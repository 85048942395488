import {isString, get} from 'lodash-es';
import {IJoinParameter} from '../models';

export const applyJoin = (object: {}, params: IJoinParameter) => {
  const separator = params.separator ?? ', ';
  return params.fields
    .map((f: any) => get(object, f) ?? null)
    .filter(isString)
    .join(separator);
};
