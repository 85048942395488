export const debounce = <T extends (...args: any[]) => void | Promise<void>>(
  func: T,
  delay: number
): ((...args: Parameters<T>) => void) => {
  let last: number | null = null;
  const clearLast = () => {
    if (last) {
      clearTimeout(last);
      last = null;
    }
  };
  return new Proxy(func, {
    apply(target, thisArg, argArray) {
      clearLast();
      last = window.setTimeout(() => {
        Reflect.apply(target, thisArg, argArray);
        last = null;
      }, delay);
    }
  });
};
