/** @jsx jsx */
import {css, jsx, keyframes} from '@emotion/react';
export const TOP = 'calc(235rem / var(--bfs))';
export const RIGHT = 'calc(240rem / var(--bfs))';
export const BOTTOM = 'calc(95rem/var(--bfs))';
export const WIDTH = `calc(100vw - 2 * ${RIGHT})`;
export const HEIGHT = `calc(100vh - ${TOP} - ${BOTTOM})`;
export const PICTURE_WIDTH = `calc(96rem / var(--bfs))`;

export const $zoomIndicator = css``;
export const $picture = css`
  cursor: pointer;

  position: relative;
  overflow: hidden;
  width: ${PICTURE_WIDTH};
  height: calc(60rem / var(--bfs));
  display: flex;
  align-items: center;
  justify-self: center;

  video {
    max-width: 100%;
    max-height: 100%;
    display: block;
    pointer-events: none;
    background: var(--player-video-bg);
  }

  [data-name='magnify'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;
    transition: opacity var(--transition-duration) ease;
  }
  &:hover > [data-name='magnify'],
  [data-name='magnify'][data-show='true'] {
    opacity: 1;
  }

  [data-name='icon'] {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: white;
  }

  [data-status='loading'] {
    color: var(--text-color);
  }

  [data-status='error'] {
    color: var(--error-color);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const $bigPicture = css`
  pointer-events: auto;
  position: relative;
  width: min-content;
  border-radius: var(--border-radius);
  box-shadow: var(--overlay-shadow);
  overflow: hidden;
  background: var(--player-video-bg);
  animation: ${fadeIn} var(--transition-duration) ease;
`;

export const $bigVideo = css`
  height: 100%;
  display: block;
  pointer-events: none;
  background: var(--player-video-bg);
  max-width: ${WIDTH};
  max-height: ${HEIGHT};
`;

export const $overlay = css`
  position: absolute;
  top: 0;
  left: 0;

  pointer-events: none;
  canvas {
    display: block;
  }
`;

export const $closeButton = css`
  position: absolute;
  cursor: pointer;
  top: var(--spacer-xs);
  right: var(--spacer-xs);
  background: var(--player-video-bg);
  border-radius: 50%;
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--spacer);
  height: var(--spacer);
`;
