import {get} from 'lodash-es';

import {
  refSerializeCell,
  enumSerializerCell,
  textSerializeCell,
  dateTimeSerializeCell,
  refTransformSerializeCell
} from '../components/cells';

import {
  CellType,
  isTextCell,
  isDateTimeCell,
  isRefCell,
  isRefTransform,
  isEnumCell,
  isTboContainerCell,
  isPictureCell
} from '../models';

import {tboContainersSerializerCell} from '../components/helpers/TboContainers';

export const getSerializerCellByType = <T extends {[k: string]: any}, C extends CellType>(
  rowData: T,
  cell: C,
  field: string
) => {
  const value = get(rowData, field);

  if (isTextCell(cell)) return textSerializeCell(value);
  if (isDateTimeCell(cell)) return dateTimeSerializeCell(value);
  if (isRefCell(cell)) return refSerializeCell({description: cell, rowData});
  if (isRefTransform(cell)) return refTransformSerializeCell({description: cell, rowData});
  if (isEnumCell(cell)) return enumSerializerCell({cell, value});
  if (isTboContainerCell(cell)) return tboContainersSerializerCell(rowData as any);
  if (isPictureCell(cell)) return '-';

  return 'type cell error';
};
