import {IFilterDescription} from '@netvision/lib-react-table';
import {Locale} from '../locale';
import {TableSettings} from '../models';
import {getColumnFilterData} from './getColumnFilterData';

const isNotNull = <T>(v: T): v is NonNullable<T> => v !== null;

export const createTableFilters = (tableSettings: TableSettings<any>, locale: Locale): IFilterDescription<string> => {
  const entries = Object.entries(tableSettings?.columns || {})
    .map(([columnName, columnSetting]) => {
      const filterColumnData = getColumnFilterData(columnName, columnSetting, locale);
      if (filterColumnData) return [columnName, filterColumnData];
      return null;
    })
    .filter(isNotNull);
  return Object.fromEntries(entries);
};
