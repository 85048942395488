import React, {ReactElement} from 'react';
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {Drill} from '../ui/icons/Drill';
import {Success} from '../ui/icons/Success';
import {Attention} from '../ui/icons/Attention';
import {Close} from '../ui/icons/Close';
import {EnumCell as EnumCellP} from '../../models';
type EnumCellProps = {
  value: string;
  cell: EnumCellP;
};

const iconMap: {
  [k: string]: ReactElement;
} = {
  FalsePositive: <Success />,
  Handled: <Attention />,
  NotHandled: <Close />,
  ViolationDetected: <Drill />
};

export const EnumCell = (props: EnumCellProps) => {
  const {cell, value} = props;
  const {options} = cell;
  const {label, value: optionValue} = options.find((option) => option.value === value) || {};
  const icon = (optionValue && iconMap[optionValue]) || null;
  return (
    <span css={wrapper}>
      {icon}
      {label ?? '-'}
    </span>
  );
};

export const enumSerializerCell = (data: EnumCellProps) => {
  const {cell, value} = data;
  const {options} = cell;
  const {label} = options.find((option) => option.value === value) || {};
  return label || '';
};

const wrapper = css`
  & {
    display: flex;
    align-items: center;

    > div:first-of-type {
      display: block;
      margin-right: calc(10rem / var(--bfs));
    }
  }
`;
