import { IEntity } from '@netvision/lib-api-repo';
import {ISerializers} from '@netvision/lib-react-table';

import {LocaleMetaData, TableSettings} from '../models';
import {getSerializerCellByType} from './getSerializerCellByType';

export const createSerializeTableData = (
  settings: TableSettings<any>,
  metaData: LocaleMetaData
): ISerializers<IEntity, string> => {
  const {columns} = settings;

  return Object.fromEntries(
    Object.entries(columns || {}).map(([columnName, columnSettings]) => {
      const {cell} = columnSettings;
      return [columnName, (rowData: IEntity) => getSerializerCellByType(rowData, cell, columnName)];
    })
  );
};
