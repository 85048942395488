import React, {useCallback, useState} from 'react';
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {getCellByType} from '../../helpers/getCellByType';
import {ScheduleCell, EventsTypes} from '../../models';
import {Tooltip} from 'primereact/tooltip';
import {TboScheduleAdapter} from '../../widget-adapters';
import {get, noop, uniqueId} from 'lodash-es';

const tableScheduleByEvent = {
  [EventsTypes.TboEvent]: TboScheduleAdapter,
  [EventsTypes.AssignmentEvent]: null
};

type ScheduleCellAdapterProps = {
  rowData: any;
  eventType: EventsTypes;
  cell: ScheduleCell;
  field: string;
  attrs?: Record<string, string>;
};

export const ScheduleCellAdapter = ({rowData, eventType, cell, field, attrs}: ScheduleCellAdapterProps) => {
  const Schedule = tableScheduleByEvent[eventType];
  const [open, setOpen] = useState(false);
  const onHide = useCallback(() => setOpen(false), []);
  const tooltipId = uniqueId();
  const openSchedule = useCallback((e) => {
    e.stopPropagation();
    setOpen(true);
  }, []);

  const complexObjectId = get(rowData, field);
  if (!Schedule) return null;

  return (
    <span role="button" css={$wrapper} onClick={complexObjectId ? openSchedule : noop}>
      {getCellByType(rowData, cell.label, field)}
      <Tooltip target={`.tbo-complex-object-schedule-${tooltipId}`} content="График вывоза мусора"></Tooltip>
      {complexObjectId && <i className={`mdi mdi-18px mdi-launch tbo-complex-object-schedule-${tooltipId}`} />}
      {open && <Schedule {...rowData} onHide={onHide} />}
    </span>
  );
};

const $wrapper = css`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;

  & > i {
    margin-left: var(--spacer-xs);
    margin-right: var(--spacer-xs);

    transition: opacity var(--transition-duration) ease;
  }

  tr & > i {
    opacity: 0;
  }

  tr:hover & > i {
    opacity: 1;
  }
`;
