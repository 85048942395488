import { IEntity, IListPayload } from '@netvision/lib-api-repo';
import { get } from 'lodash-es';
import store from './store'

export const searchById = <E extends IEntity>(type: IEntity['type'], id: string | string[]): Promise<E[]> => {
  id = join(id, ',');
  return id.length > 0 ? load({ limiter: { id, type: join(type, ',') } }) : Promise.resolve([]);
};

export const searchByField = <E extends IEntity>(
  type: IEntity['type'],
  query: string,
  field: string,
  limit: number
): Promise<E[]> =>
  load({
    limiter: {
      type: join(type, ','),
      orderBy: field,
      limit
    },
    filter: {
      q: query.length > 0 ? [
        {
          key: field,
          value: query,
          operator: '~=',
          insensitify: true,
          sanitize: true
        }
      ] : undefined
    }
  })

export const load = <E extends IEntity>(params: IListPayload): Promise<E[]> => 
  store.api.getEntitiesList<E>({ ...params })
    .then((response) => (response?.results ?? []) as E[])
    .catch(() => []);

export const join = (s: string | string[], separator: string) => (Array.isArray(s) ? s.join(separator) : s);

export const optionsFromEntities = (labelField: string, idField?: string) => (entities: IEntity[]) => {
  return entities.map((en) => ({value: get(en, idField || 'id') as string, label: get(en, labelField) as string}));
};
