import React, {useMemo} from 'react';
import {get} from 'lodash-es';
import {useEntity} from '../../hooks/useEntity';
import {applyJoin} from '../../utils/applyJoin';
import {getCellByType} from '../../helpers/getCellByType';
import {RefTransformCell as RefTransformCellProps} from '../../models';
import {fetchEntity} from '../../utils';

type Props<T extends {}> = {
  rowData: T;
  description: RefTransformCellProps;
};
// Todo parseTemplateString
const replacePattern = '{{id}}';
const transformId = (value: string, template: string) => template.replace(replacePattern, value);

export const RefTransformCell = (props: Props<{}>) => {
  const {rowData, description} = props;
  const {idField, typeStatic, transformTemplate, join = {fields: ['title']}} = description;
  const id = useMemo(() => {
    const value = get(rowData, idField);
    return transformId(value, transformTemplate);
  }, [idField, rowData, transformTemplate]);

  const [loading, , entity] = useEntity(typeStatic, id);
  const text = useMemo(() => (entity ? applyJoin(entity, join) : ''), [entity, join]);

  if (loading) return null;
  return !text ? <span>{'-'}</span> : <span>{entity && getCellByType(entity, description.scheme, idField)}</span>;
};

export const refTransformSerializeCell = (data: Props<{}>) => {
  const {rowData, description} = data;
  const {idField, typeStatic, transformTemplate, join = {fields: ['title']}} = description;
  const value = get(rowData, idField);
  const id = transformId(value, transformTemplate);
  const type = typeStatic ? typeStatic : '';
  if (!id || !type) return '-';
  return fetchEntity(type, id).then((entity) => {
    const text = entity ? applyJoin(entity, join) : '';
    return text || '-';
  }).catch((e) => {
    console.error(e)
    return '-'
  });
};
