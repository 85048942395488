import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { INgsiOnLoadEvent, Table } from '@netvision/lib-react-table';
import { useLocale } from '../hooks/useLocale';
import { useEventData, useWidgetProps } from '../Root';
import { name } from '../../package.json';
import { EventDetailsAdapter } from '../widget-adapters';
import { useGetUserInfo } from '../hooks/useGetUserInfo';
import { EventStructure } from '../events-config';
import { BigPictureProvider } from '../components/cells/PictureCell';
import { IntersectionObserverProvider } from '../providers/IntersectionObserverProvider';
import { isEmpty, union } from 'lodash-es';
import { history, SearchParams } from '@netvision/lib-history';
import { useApiRepository } from '../hooks/useApiRepository';
import { EventsTypes, IAssignmentEvent, ITboEvent } from '../models';
import { Toast } from 'primereact/toast';

export type iSelectedEvent = (EventStructure & {canPrev: boolean; canNext: boolean}) | null;
export type DataSourceLoad = INgsiOnLoadEvent<ITboEvent | IAssignmentEvent, string>;

export const Main = observer(() => {
  const locale = useLocale();
  const { props = {} } = useWidgetProps();
  const { api } = useApiRepository()
  const lastLoadData = useRef<DataSourceLoad>(null!);
  const toastRef = useRef<Toast>(null!);

  const nextOpenEventDirection = useRef<boolean>(null!);
  const isAlreadyChangeTablePage = useRef<boolean>(false);

  const { groupFields = [], showPageReport, eventName = EventsTypes.AssignmentEvent, canvasStyles } = props;

  const { dataSource, defaultGroupingFields, defaultPage, serializer, useGetFilterDescription, useGetAllColumns } =
    useEventData();
  const columns = useGetAllColumns();
  const fd = useGetFilterDescription();

  const { userId = null } = useGetUserInfo();
  const [selectedEvent, setSelectedEvent] = useState<iSelectedEvent>();
  const groupFieldsMerged = union(groupFields, defaultGroupingFields);


  const onRowClick = useCallback((e: {data: EventStructure; index?: number}) => {
    if (isEmpty(e.data)) return;
    const {
      page: {first, rows},
      result: {total, data}
    } = lastLoadData.current;
    const isFirstPage = first === 0;
    const isFirstEvent = e?.index === 0;
    const isLastPage = first + rows >= total;
    const isLastEvent = data.length - 1 === e?.index;

    setSelectedEvent({
      ...e.data,
      canPrev: !(isFirstEvent && isFirstPage),
      canNext: !(isLastPage && isLastEvent)
    } as iSelectedEvent);
    pushEventIdToUrl(e.data?.id);
  }, []);

  const onHide = useCallback((needRefresh: boolean) => {
    clearEventIdFromURL();
    setSelectedEvent(null);
    needRefresh && lastLoadData.current.forceLoad();
  }, []);

  const reactOnEventOnUrl = useCallback(async () => {
    const {event: eventId} = SearchParams.parse(history.location.search) as {
      event: string | undefined;
    };
    if (!eventId) return;
    try {
      const { entity } = await api.getEntity({
        id: eventId,
        type: eventName,
        keyValues: true
      })
      if (!entity) return;
      setSelectedEvent({...entity, canNext: false, canPrev: false} as iSelectedEvent);
    } catch (error) {
      console.error(error);
      toastRef.current.show({ 
          severity: 'warn', 
          summary: locale.toastContent.content
        });
    }
  }, [eventName, locale]);

  useEffect(() => {
    reactOnEventOnUrl();
  }, [reactOnEventOnUrl]);

  const onSlideEvent = useCallback(
    (flag) => () => {
      if (!lastLoadData.current || !selectedEvent) return;
      const {
        page: {first, rows},
        result: {total, data},
        setPage
      } = lastLoadData.current;
      const indexSelectedEvent = data.findIndex(({id}) => selectedEvent.id === id);
      if (indexSelectedEvent === -1) return;
      const isLastEventAndClickNextEvent = indexSelectedEvent === data.length - 1 && flag;
      const isFirstEventAndClickPrevEvent = indexSelectedEvent === 0 && !flag;

      if (isLastEventAndClickNextEvent || isFirstEventAndClickPrevEvent) {
        setPage((prev) => {
          const result = flag ? prev.first + prev.rows : prev.first - prev.rows;
          nextOpenEventDirection.current = flag;
          return {
            ...prev,
            first: Math[flag ? 'min' : 'max'](result, flag ? total : 0)
          };
        });
        isAlreadyChangeTablePage.current = true;
        return;
      }

      const nextIndex = flag ? indexSelectedEvent + 1 : indexSelectedEvent - 1;

      const isFirstPage = first === 0;
      const isLastPage = first + rows >= total;
      const isFirstEvent = nextIndex === 0;
      const isLastEvent = data.length - 1 === nextIndex;

      if (nextIndex < 0) return;
      setSelectedEvent({
        ...data[nextIndex],
        canPrev: !(isFirstEvent && isFirstPage),
        canNext: !(isLastPage && isLastEvent)
      } as iSelectedEvent);
      pushEventIdToUrl(data[nextIndex]?.id);
    },
    [selectedEvent]
  );

  const onSlideEventNext = useMemo(() => onSlideEvent(true), [onSlideEvent]);
  const onSlideEventPrev = useMemo(() => onSlideEvent(false), [onSlideEvent]);

  dataSource.onLoad = (e: DataSourceLoad) => {
    if (!e.result.data) return;
    lastLoadData.current = e;

    const {
      page: {first, rows},
      result: {total, data}
    } = e;
    const isFirstPage = first === 0;
    const isLastPage = first + rows >= total;

    setSelectedEvent((prevEvt) => {
      if (!prevEvt) return;

      // alreadyOpen event and sameEvent data update case
      const updatedEventData = e.result.data.find(({id}) => prevEvt.id === id);
      if (updatedEventData) {
        return prevEvt.status !== updatedEventData.status
          ? ({
              ...updatedEventData,
              canPrev: prevEvt.canPrev,
              canNext: prevEvt.canNext
            } as iSelectedEvent)
          : prevEvt;
      }

      if (!isAlreadyChangeTablePage.current) return prevEvt;

      // table page change case
      const newEventDate = e.result.data;
      const isNextPageDirection = nextOpenEventDirection.current;
      const dataPosition = isNextPageDirection ? 0 : newEventDate.length - 1;
      const isFirstEvent = dataPosition === 0;
      const isLastEvent = data.length - 1 === dataPosition;

      const newData = {
        ...newEventDate[dataPosition],
        canPrev: !(isFirstEvent && isFirstPage),
        canNext: !(isLastPage && isLastEvent)
      } as iSelectedEvent;
      return newData ?? null;
    });
  };

  return (
    <IntersectionObserverProvider>
      <BigPictureProvider>
        {userId && (
          <Table
            title={locale.journalTitle}
            dataKey={'id'}
            defaultGroupingFields={groupFieldsMerged}
            preferencesLocalStorageKey={`${name}:table-settings-by-user-${userId}`}
            numberOfPinnedColumns={2}
            dataSource={dataSource}
            columns={columns}
            defaultPage={defaultPage}
            filterDescription={fd}
            onRowClick={onRowClick}
            showPageReport={showPageReport}
            serializers={serializer}
            allowExport={true}
          />
        )}
        {selectedEvent && (
          <EventDetailsAdapter
            onNext={onSlideEventNext}
            onPrev={onSlideEventPrev}
            onHide={onHide}
            event={selectedEvent}
            eventName={eventName}
            canvasStyles={canvasStyles}
          />
        )}
        <Toast ref={toastRef} position="bottom-left" />
      </BigPictureProvider>
    </IntersectionObserverProvider>
  );
});

function clearEventIdFromURL() {
  const query = SearchParams.parse(history.location.search);
  delete query.event;
  history.push({
    search: SearchParams.stringify(query)
  });
}

function pushEventIdToUrl(eventId: string | undefined) {
  if (!eventId) return;
  const query = SearchParams.parse(history.location.search);
  history.push({
    search: SearchParams.stringify({
      ...query,
      event: eventId
    })
  });
}
