import {makeAutoObservable} from 'mobx';
import {repositoryGetter, TUnionRepo, TLibProp} from '@netvision/lib-api-repo';

class Store {
  api = {} as TUnionRepo;
  lib?: TLibProp;
  isInit = false;

  constructor() {
    makeAutoObservable(this, {}, {autoBind: true});
  }

  setApiRepository(lib?: TLibProp) {
    this.api = repositoryGetter(lib);
    this.lib = lib;
    this.isInit = true;
  }
}

export default new Store();
