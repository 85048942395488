export const containerTypes = {
  COMMON: 'Обычный',
  MESH: 'Сетчатый',
  BUNKER: 'Бункер',
  UNKNOWN: 'Другой'
};

export const eventStatusLocale = {
  NotHandled: 'Ожидает обработки',
  FalsePositive: 'Ложное',
  Handled: 'Обработано',
  ViolationDetected: 'Тревога'
};

export const common = {
  from: 'из',
  allowed: 'допустимых',
  search: 'Поиск',
  noneText: 'Отсутствует',
  anyText: 'Любое значение'
};

export const toastContent = {
  content: 'Событие не найдено',
}

export const defaultLocale = {
  journalTitle: 'Журнал событий',
  ...common,
  containerTypes,
  eventStatusLocale,
  toastContent
};

export type Locale = typeof defaultLocale;
