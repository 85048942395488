import { IEntity } from '@netvision/lib-api-repo';
import { IColumn } from '@netvision/lib-react-table';
import { LocaleMetaData, TableSettings, EventsTypes } from '../models';
import { getCellByType } from './getCellByType';
import { merge } from 'lodash-es';

const createOrderIndexMapByKey = (order: string[]): Map<string, number> => {
  const mapOrder = new Map();
  order.forEach((key, i) => mapOrder.set(key, i + 1));
  return mapOrder;
};

export const createTableColumns = (
  settings: TableSettings<any>,
  metaData: LocaleMetaData,
  entityType: EventsTypes,
  columnOverride?: {}
): IColumn<IEntity, string>[] => {
  const {order = [], columns = {}} = settings;
  const orderIndexByKeyMap = createOrderIndexMapByKey(order);
  const {attrs} = metaData;

  return Object.entries(merge(columnOverride, columns))
    .filter(([_, columnSettings]) => !columnSettings.disabled)
    .map(([columnName, columnSettings]) => {
      const {sortable, required, hidden, cell, pinToEnd, style} = columnSettings;
      const col: IColumn<IEntity, string> = {
        field: columnName,
        header: attrs?.[columnName] ?? columnName,
        body: (rowData: IEntity) => getCellByType(rowData, cell, columnName, entityType, attrs),
        sortable,
        style
      };

      pinToEnd && (col['pinToEnd'] = pinToEnd);
      required && (col['requiredColumn'] = required);
      hidden && (col['hideByDefault'] = hidden);
      return col;
    })
    .sort(({field: fieldA}, {field: fieldB}) => {
      const orderA = orderIndexByKeyMap.get(fieldA);
      const orderB = orderIndexByKeyMap.get(fieldB);
      if (orderA && orderB) return orderA - orderB;
      if (fieldA && !fieldB) return -1;
      if (!fieldA && fieldB) return 1;
      if (fieldA < fieldB) return 1;
      return 0;
    });
};
