import React from 'react';

const timestampToString = (t: number | string) => new Date(t).toLocaleString();

type Props = {
  timeStamp: number | string;
};

export const DateTimeCell = (props: Props) => {
  const {timeStamp} = props;
  return <span>{timeStamp ? timestampToString(timeStamp) : ''}</span>;
};

export const dateTimeSerializeCell = (timeStamp: number) => (timeStamp ? timestampToString(timeStamp) : '-');
