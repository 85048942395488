import React, { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { fetchEntity as utilsFetchEntity } from '../utils';
import { IEntity } from '@netvision/lib-api-repo';
import { IBatchQueue } from '../utils/batchQueue';

type IFetchEntity = <T extends IEntity>(type: string, id: string) => Promise<T>;
type _IQueue = IBatchQueue<[string, string], IEntity>;

export const FetchEntityCtx = createContext<{
  fetchEntity: IFetchEntity;
  resetCache: () => void;
}>(null!);

export const useEntityCacheReset = () => {
  return useContext(FetchEntityCtx).resetCache;
};

export const useEntity = <T extends IEntity>(
  type: string,
  id: string
): [loading: boolean, error: boolean, entity: T | null] => {
  const {fetchEntity} = useContext(FetchEntityCtx);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [entity, setEntity] = useState<T | null>(null);

  useEffect(() => {
    let aborted = false;

    if (type.length === 0 || id.length === 0) {
      setEntity(null);
      setLoading(false);
      return;
    }

    const getEntity = async () => {
      try {
        const en = await fetchEntity<T>(type, id);
        !aborted && setEntity(en);
      } catch (e) {
        console.error(e);
        !aborted && setError(true);
      } finally {
        !aborted && setLoading(false);
      }
    };

    if (aborted) return;
    setEntity(null);
    setLoading(true);
    getEntity();

    return () => {
      aborted = true;
    };
  }, [fetchEntity, type, id]);
  return [loading, error, entity];
};

export const useEntities = (type: string, ids: string[]) => {
  const {fetchEntity} = useContext(FetchEntityCtx);

  const [loading, setLoading] = useState(false);
  const [entityMap, setEntityMap] = useState<Map<string, IEntity | null>>(aMap);
  const idKey = useMemo(() => JSON.stringify([...new Set(ids)].sort()), [ids]);

  useEffect(() => {
    const idList = JSON.parse(idKey) as string[];
    let aborted = false;

    if (type.length === 0 || idList.length === 0) {
      setEntityMap(aMap);
      setLoading(false);
      return;
    }

    setEntityMap(aMap);
    setLoading(true);

    const getEntity = async (id: string) => {
      try {
        if (aborted) return;
        const en = await fetchEntity(type, id);
        if (aborted || !en) return;
        setEntityMap((prev) => {
          prev.set(id, en);
          return new Map(prev);
        });
      } catch (e) {
        console.error(e);
      }
    };

    const pAll = idList.map(getEntity);
    Promise.all(pAll).finally(() => {
      !aborted && setLoading(false);
    });

    return () => {
      aborted = true;
    };
  }, [fetchEntity, type, idKey]);
  return [loading, entityMap] as const;
};

export const FetchEntityProvider: FC = ({children}) => {
  const [batchMap] = useState<Map<string, _IQueue>>(aMap);
  const [cache, setCache] = useState<Map<string, IEntity>>(aMap);

  const fetchEntity = useCallback<IFetchEntity>(
    (type: string, id: string) => utilsFetchEntity(type, id, cache, batchMap),
    [batchMap, cache]
  );
  const resetCache = useCallback(() => setCache(aMap()), []);
  const value = useMemo(() => ({fetchEntity, resetCache}), [fetchEntity, resetCache]);
  return <FetchEntityCtx.Provider value={value}>{children}</FetchEntityCtx.Provider>;
};

const aMap = <K, T>() => new Map<K, T>();
