/** @jsx jsx */
import {jsx} from '@emotion/react';
import {FC} from 'react';

export const ErrorPicture: FC<JSX.IntrinsicElements['svg']> = () => {
  return (
    <div>
      <svg width="100" height="64" viewBox="80 180 520 113" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.4" filter="url(#filter0_f)">
          <path
            d="M491.841 160.593L143.784 300.241L181.889 418.624L284.873 385.476L491.841 160.593Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <g opacity="0.4" filter="url(#filter1_f)">
          <path
            d="M168.82 94.0001L480.471 302.611L418.707 410.555L324.805 356.825L168.82 94.0001Z"
            fill="url(#paint1_linear)"
          />
        </g>
        <path
          opacity="0.5"
          d="M447.767 184.583L186.947 289.23L201.225 333.585L215.502 377.941L292.673 353.101L447.767 184.583Z"
          fill="url(#paint2_linear)"
        />
        <path
          opacity="0.8"
          d="M205.71 134.682L439.249 291.007L392.966 371.895L322.599 331.632L205.71 134.682Z"
          fill="url(#paint3_linear)"
        />
        <path
          opacity="0.8"
          d="M447.767 184.583L215.502 377.941L292.673 353.101L447.767 184.583Z"
          fill="url(#paint4_linear)"
        />
        <path
          opacity="0.8"
          d="M205.71 134.682L392.966 371.895L322.599 331.632L205.71 134.682Z"
          fill="url(#paint5_linear)"
        />
        <defs>
          <filter
            id="filter0_f"
            x="41.7295"
            y="66.5925"
            width="590.271"
            height="446.031"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="47" result="effect1_foregroundBlur" />
          </filter>
          <filter
            id="filter1_f"
            x="0"
            y="0"
            width="587.527"
            height="504.555"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="47" result="effect1_foregroundBlur" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="610.645"
            y1="104.632"
            x2="162.802"
            y2="427.231"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF3C3C" />
            <stop offset="1" stopColor="#FF3C3C" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="64.1573"
            y1="14.6789"
            x2="435.602"
            y2="422.923"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF3C3C" />
            <stop offset="1" stopColor="#FF3C3C" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="536.795"
            y1="142.648"
            x2="201.199"
            y2="384.391"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF3C3C" />
            <stop offset="0.816895" stopColor="#FF3C3C" stopOpacity="0.183105" />
            <stop offset="1" stopColor="#FF3C3C" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="127.28"
            y1="75.2416"
            x2="405.626"
            y2="381.163"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF3C3C" />
            <stop offset="1" stopColor="#FF3C3C" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="149.295"
            y1="322.324"
            x2="239.861"
            y2="413.859"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF3C3C" />
            <stop offset="1" stopColor="#FF3C3C" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="469.244"
            y1="331.172"
            x2="361.704"
            y2="401.999"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF3C3C" />
            <stop offset="1" stopColor="#FF3C3C" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};
