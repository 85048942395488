/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {$json} from './style';
import React, {FC, useCallback, useMemo, useRef} from 'react';
import {Button} from 'primereact/button';

type JsonFieldProp = {object: {}};

const JsonFieldComponent: FC<JsonFieldProp> = ({object}) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const value = useMemo(() => {
    let s = JSON.stringify(object, null, 2);
    if (s?.startsWith('[')) return s.replace('[\n ', '[').replace('\n]', ' ]');
    if (s?.startsWith('{')) return s.replace('{\n ', '{').replace('\n}', ' }');
    return s;
  }, [object]);

  const copyJson = useCallback(() => {
    if (!ref.current) return;
    ref.current.value = value;
    ref.current.select();
    document.execCommand('copy');
  }, [value]);

  return (
    <span css={$json}>
      <textarea ref={ref} value={value} readOnly={true} rows={3} />
      <Button
        className="p-button-text p-button-secondary"
        icon="mdi mdi-18px mdi-content-copy"
        tooltip="Копировать"
        onClick={copyJson}
      />
    </span>
  );
};

export const JsonField = React.memo(JsonFieldComponent);
