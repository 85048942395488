import {css} from '@emotion/react';

export const $json = css`
display: flex;
flex-grow: 1;
position: relative;
> textarea {
  font-family: monospace;
  font-size: calc(12rem / var(--bfs));
  width: 100%;
  overflow: hidden;
  resize: none;
  margin-left: calc(-11rem / var(--bfs));
  padding: 0 calc(40rem / var(--bfs)) 0 calc(10rem / var(--bfs));
  color: var(--text-color-secondary);
  border: calc(1rem/ var(--bfs)) solid transparent;
  background: transparent;
  outline: none;
  transition: border-color var(--transition-duration) ease;
}
&:hover > textarea {
  overflow: auto;
  outline: none;
  border-color: var(--text-color);
  border-radius: var(--border-radius-sm);
  color: var(--text-color);
}
> button {
  position: absolute;
  right: calc(var(--spacer-xs) / 2);
  top: calc(var(--spacer-xs) / 2);
  padding: 0 !important;
  height: auto; !important;
  pointer-events: none;
  opacity: 0;
  transition: opacity var(--transition-duration) ease;
}
&:hover > button {
  opacity: 1;
  pointer-events: auto;
}
`;
