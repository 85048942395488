import React, {createContext, FC, useContext, useMemo} from 'react';

type ICallback = (entry: IntersectionObserverEntry) => void;
type ISubscribe = (target: HTMLElement, callback: ICallback) => IDisposer;
type IDisposer = () => void;

const ctx = createContext<ISubscribe>(null!);

export const IntersectionObserverProvider: FC = ({children}) => {
  const subscribe = useMemo<ISubscribe>(() => {
    const clients = new Map<Element, ICallback>();
    let io: IntersectionObserver | null;
    return (target, callback) => {
      if (!io) {
        io = new IntersectionObserver((entries) => {
          entries.forEach((en) => {
            clients.get(en.target)?.(en);
          });
        });
      }
      clients.set(target, callback);
      io.observe(target);
      return () => {
        clients.delete(target);
        if (io) {
          io.unobserve(target);
          if (clients.size === 0) {
            io.disconnect();
            io = null;
          }
        }
      };
    };
  }, []);
  return <ctx.Provider value={subscribe}>{children}</ctx.Provider>;
};

export const useIntersectionObserver = () => {
  return useContext(ctx);
};
