import {ColumnEnumFilter} from '../../../models';

export const createEnumFilterData = (filter: ColumnEnumFilter): ColumnEnumFilter => {
  const {options, field} = filter;
  return {
    type: 'enum',
    field,
    options
  };
};
