import {defaultsDeep} from 'lodash-es';
import {ITboEvent} from '../models/';

export interface OverlayParams {
  /**
   * Element that contains overlay.
   */
  overlayContainer: HTMLElement;
  /**
   * This element is used to calculate sizes.
   * ResizeObserver tracks changes
   */
  overlayBase: HTMLElement;
  /**
   * This sizes are used as a maximum value for coordinates.
   * Also this is used to to place canvas properly,
   * in case when aspect ratio of video or image does not correspond to it's container aspect ratio (black borders).
   */
  naturalHeight: number;
  naturalWidth: number;
}

const defaultStyles = {
  area: {
    fillColor: 'rgba(50, 50, 130, 0.1)',
    strokeColor: 'rgba(50, 50, 130, 1)'
  },
  line: {
    strokeColor: 'rgba(50, 50, 130, 1)'
  },
  fallback: {
    fillColor: 'rgba(230, 50, 50, 0.1)',
    strokeColor: 'rgba(230, 50, 50, 1)'
  },
  plate: {
    strokeColor: 'rgba(230, 50, 50, 1)'
  },
  vehicle: {
    strokeColor: 'rgba(230, 50, 50, 1)'
  }
};

export type ICanvasOverrideStyles = Partial<typeof defaultStyles>;

const mergeStyles = (styles: ICanvasOverrideStyles): typeof defaultStyles => {
  return defaultsDeep(styles, defaultStyles);
};

export function getCanvasInitState(
  event: ITboEvent,
  naturalSize: {width: number; height: number},
  styles: ICanvasOverrideStyles
) {
  const mergedStyles = mergeStyles(styles);
  const polygons = [];
  // event zone
  const {bbox} = event;
  if (bbox !== undefined) {
    const [left, top, width, height] = bbox;
    polygons.push({
      id: event.id,
      editable: false,
      style: mergedStyles.fallback,
      points: [
        {x: left, y: top},
        {x: left + width, y: top},
        {x: left + width, y: top + height},
        {x: left, y: top + height}
      ]
        .map((val) => {
          val.x = val.x * naturalSize.width;
          val.y = val.y * naturalSize.height;
          return val;
        })
        .map((val, index) => Object.assign(val, {index}))
    });
  }
  return {
    itemStore: {
      polygonStore: {
        items: polygons,
        editableLimit: 0
      },
      lineStore: {
        items: [],
        editableLimit: 0
      }
    }
  };
}
