/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {createIconSizesCss} from './createIconSizesCss';

const strokeErrorColorCss = css`
  & {
    stroke: var(--error-color);
  }
`;

const strokeTextColorCss = css`
  & {
    stroke: var(--text-color);
  }
`;

const fillTextColorCss = css`
  & {
    fill: var(--text-color);
  }
`;

const sizesCss = createIconSizesCss(20, 28);

export const Drill = () => {
  return (
    <div css={sizesCss}>
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 4.21212C13.6104 4.21212 13.2271 4.23743 12.8515 4.28643L12.2505 4.36482L12.0937 3.16288L12.6947 3.08449C13.1222 3.02873 13.5579 3 14 3C14.4421 3 14.8778 3.02873 15.3053 3.08449L15.9063 3.16288L15.7495 4.36482L15.1485 4.28643C14.7729 4.23743 14.3896 4.21212 14 4.21212ZM19.6076 4.69701L20.0882 5.0663C20.7807 5.59848 21.4015 6.21929 21.9337 6.91183L22.303 7.39239L21.3419 8.13096L20.9726 7.65041C20.5046 7.04141 19.9586 6.4954 19.3496 6.02741L18.869 5.65812L19.6076 4.69701ZM9.13096 5.65812L8.65041 6.02741C8.04141 6.4954 7.4954 7.04141 7.02741 7.65041L6.65812 8.13097L5.69701 7.39239L6.0663 6.91183C6.59848 6.21929 7.21929 5.59848 7.91183 5.0663L8.39239 4.69701L9.13096 5.65812ZM23.8371 11.0937L23.9155 11.6947C23.9713 12.1222 24 12.5579 24 13V14.5152H22.7879V13C22.7879 12.6104 22.7626 12.2271 22.7136 11.8515L22.6352 11.2505L23.8371 11.0937ZM5.36482 11.2505L5.28643 11.8515C5.23743 12.2271 5.21212 12.6104 5.21212 13V14.5152H4V13C4 12.5579 4.02873 12.1222 4.08449 11.6947L4.16288 11.0937L5.36482 11.2505Z"
          fill="white"
          css={fillTextColorCss}
        />
        <line x1="4" y1="22" x2="23.3939" y2="22" stroke="white" strokeWidth="2" css={strokeTextColorCss} />
        <path
          d="M8 13C8 9.68629 10.6863 7 14 7C17.3137 7 20 9.68629 20 13V18H8V13Z"
          stroke="#FF3C3C"
          strokeWidth="2"
          css={strokeErrorColorCss}
        />
        <g filter="url(#filter0_f)">
          <path
            d="M19.9753 14V19.9753H8.02469V14C8.02469 10.6999 10.6999 8.02469 14 8.02469C17.3001 8.02469 19.9753 10.6999 19.9753 14Z"
            stroke="#FF3C3C"
            css={strokeErrorColorCss}
            strokeWidth="2.04938"
          />
        </g>
        <defs>
          <filter
            id="filter0_f"
            x="0"
            y="0"
            width="28"
            height="28"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="3.5" result="effect1_foregroundBlur" />
          </filter>
        </defs>
      </svg>
    </div>
  );
  /**
   * Without glow, but with proper size
   */
  // return (
  //   <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  //     <path
  //       fillRule="evenodd"
  //       clipRule="evenodd"
  //       d="M10 1.21212C9.61044 1.21212 9.22709 1.23743 8.85145 1.28643L8.25049 1.36482L8.0937 0.162883L8.69467 0.0844916C9.12216 0.0287296 9.55788 0 10 0C10.4421 0 10.8778 0.0287295 11.3053 0.0844914L11.9063 0.162883L11.7495 1.36482L11.1485 1.28643C10.7729 1.23743 10.3896 1.21212 10 1.21212ZM15.6076 1.69701L16.0882 2.0663C16.7807 2.59848 17.4015 3.21929 17.9337 3.91183L18.303 4.39239L17.3419 5.13096L16.9726 4.65041C16.5046 4.04141 15.9586 3.4954 15.3496 3.02741L14.869 2.65812L15.6076 1.69701ZM5.13096 2.65812L4.65041 3.02741C4.04141 3.4954 3.4954 4.04141 3.02741 4.65041L2.65812 5.13097L1.69701 4.39239L2.0663 3.91183C2.59848 3.21929 3.21929 2.59848 3.91183 2.0663L4.39239 1.69701L5.13096 2.65812ZM19.8371 8.0937L19.9155 8.69467C19.9713 9.12216 20 9.55788 20 10V11.5152H18.7879V10C18.7879 9.61044 18.7626 9.22709 18.7136 8.85145L18.6352 8.25048L19.8371 8.0937ZM1.36482 8.25049L1.28643 8.85146C1.23743 9.22709 1.21212 9.61044 1.21212 10V11.5152H0V10C0 9.55788 0.0287295 9.12216 0.0844914 8.69467L0.162883 8.0937L1.36482 8.25049Z"
  //       fill="white"
  //       css={fillTextColorCss}
  //     />
  //     <line x1="8.74228e-08" y1="19" x2="19.3939" y2="19" stroke="white" strokeWidth="2" css={strokeTextColorCss} />
  //     <path
  //       d="M4 10C4 6.68629 6.68629 4 10 4C13.3137 4 16 6.68629 16 10V15H4V10Z"
  //       stroke="#FF3C3C"
  //       strokeWidth="2"
  //       css={strokeErrorColorCss}
  //     />
  //   </svg>
  // );
};
