import {
  TextColumn,
  TextColumnIcon,
  DateTimeCell,
  RefCell,
  ObjectCell,
  RefArrayCell,
  RefTransformCell,
  PictureCell,
  ScheduleCell,
  EnumCell,
  TboContainerCell
} from './columns';
import {
  ColumnEnumFilter,
  ColumnDateTimeFilter,
  ColumnAutoCompleteRefFilter,
  ColumnRefEnumFilter,
  ColumnRefFilter,
  ColumnTextFilter,
  ColumnRangeFilter
} from './filter-columns';

import {CellType, ColumnFilterType} from '.';

// Columns validators
export const isTextCell = (cell: CellType): cell is TextColumn => cell.type === 'text';
export const isTextIconCell = (cell: CellType): cell is TextColumnIcon => cell.type === 'textIcon';
export const isDateTimeCell = (cell: CellType): cell is DateTimeCell => cell.type === 'dateTime';
export const isRefCell = (cell: CellType): cell is RefCell => cell.type === 'ref';
export const isRefArrayCell = (cell: CellType): cell is RefArrayCell => cell.type === 'refArray';
export const isRefTransform = (cell: CellType): cell is RefTransformCell => cell.type === 'refTransform';
export const isObjectCell = (cell: CellType): cell is ObjectCell => cell.type === 'object';
export const isEnumCell = (cell: CellType): cell is EnumCell => cell.type === 'enum';

export const isScheduleCell = (cell: CellType): cell is ScheduleCell => cell.type === 'schedule';
export const isPictureCell = (cell: CellType): cell is PictureCell => cell.type === 'picture';
export const isTboContainerCell = (cell: CellType): cell is TboContainerCell => cell.type === 'tboContainer';

// Filters validators
export const isEnumFilter = (filter: ColumnFilterType): filter is ColumnEnumFilter => filter.type === 'enum';
export const isDateTimeFilter = (filter: ColumnFilterType): filter is ColumnDateTimeFilter =>
  filter.type === 'dateTime';
export const isRefFilter = (filter: ColumnFilterType): filter is ColumnRefFilter => filter.type === 'ref';
export const isAutoCompleteRef = (filter: ColumnFilterType): filter is ColumnAutoCompleteRefFilter =>
  filter.type === 'autocompleteRef';
export const isEnumRef = (filter: ColumnFilterType): filter is ColumnRefEnumFilter => filter.type === 'enumRef';
export const isTextFilter = (filter: ColumnFilterType): filter is ColumnTextFilter => filter.type === 'text';
export const isRangeFilter = (filter: ColumnFilterType): filter is ColumnRangeFilter => filter.type === 'range';
