import {
  isEnumFilter,
  isDateTimeFilter,
  TableColumn,
  isAutoCompleteRef,
  isRefFilter,
  isEnumRef,
  isTextFilter,
  isRangeFilter
} from '../models';
import {createEnumFilterData, createAutoCompleteRefData, createEnumRefData, createRefData} from '../components/cells';
import {Locale} from '../locale';

export const getColumnFilterData = (columnName: string, columnSetting: TableColumn, locale: Locale) => {
  const {filter} = columnSetting;
  if (!filter) return null;
  if (isEnumFilter(filter)) return createEnumFilterData(filter);
  if (isDateTimeFilter(filter)) return {...filter, type: 'time'};
  if (isRefFilter(filter)) return createRefData(filter, locale);
  if (isAutoCompleteRef(filter)) return createAutoCompleteRefData(filter, locale);
  if (isEnumRef(filter)) return createEnumRefData(filter);
  if (isTextFilter(filter)) return {...filter, placeholder: locale.search};
  if (isRangeFilter(filter)) return {...filter, noneText: locale.noneText, anyText: locale.anyText};
  return null;
};
