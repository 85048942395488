/** @jsx jsx */
import {jsx} from '@emotion/react';
import {FC, useEffect, useRef} from 'react';
import {useWidgetProps} from '../../Root';
import {Widget} from '@netvision/lib-widget-renderer';
import {IWidgetProps} from '../../IWidgetProps';
import {EventsTypes} from '../../models';
import {iSelectedEvent} from '../../components/Main';

type EventDetailsProps = {
  event: iSelectedEvent;
  onOpen?: (parentId: string) => void;
  onHide: (needRefresh: boolean) => void;
  onPrev: () => void;
  onNext: () => void;
  eventName: EventsTypes;
  canvasStyles?: {
    [k: string]: unknown;
  };
  fields?: {
    [k: string]: {disable: boolean};
  };
};

export const EventDetailsAdapter: FC<EventDetailsProps> = (props) => {
  const {event, onOpen, onHide, onNext, onPrev, eventName, canvasStyles, fields} = props;
  const {mountChildren, areas} = useWidgetProps();
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const node = ref.current;
    const dialog = getDialogWidget(areas);
    if (!node || !dialog) return;
    return mountChildren(node, [
      {
        ...dialog,
        props: {
          ...dialog?.props,
          showActions: true,
          event,
          onOpen,
          onHide,
          onNext,
          onPrev,
          canvasStyles,
          eventName,
          fields
        }
      }
    ]);
  }, [areas, canvasStyles, event, eventName, fields, mountChildren, onHide, onNext, onOpen, onPrev]);

  return <div ref={ref} />;
};

function getDialogWidget(areas: IWidgetProps['areas']): Widget | null {
  if (!Array.isArray(areas)) return null;
  const widgetDetail = areas.find((area) => area.name === 'dialog');
  if (!widgetDetail || !widgetDetail.children) return null;
  const [widget] = widgetDetail.children;
  return widget as Widget;
}
