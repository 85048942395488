import {ISearchFilter} from '@netvision/lib-react-table/dist/src/filterTypes';
import {get} from 'lodash-es';
import {optionsFromEntities, searchByField, searchById, load} from '../../../filterHelpers';
import {Locale} from '../../../locale';
import {ColumnRefEnumFilter, ColumnAutoCompleteRefFilter, ColumnRefFilter} from '../../../models';

export const createAutoCompleteRefData = (options: ColumnAutoCompleteRefFilter, locale: Locale) => {
  const {entityType, minLength = 1, labelField = 'title', limit = 20, field} = options;
  return {
    type: 'autocomplete',
    field,
    placeholder: locale.search,
    minLength: minLength,
    searchOptions: (query: string) =>
      searchByField(entityType, query, labelField, limit).then((res) => res.map((en) => get(en, labelField)))
  };
};

export const createEnumRefData = (options: ColumnRefEnumFilter) => {
  const {entityType, labelField = 'title', field, orderBy, idField} = options;
  return {
    type: 'enumLazy',
    field,
    getOptions: () => load({
      limiter: { type: entityType, orderBy, limit: 1000 }
    })
      .then(optionsFromEntities(labelField, idField))
  };
};

export const createRefData = (options: ColumnRefFilter, locale: Locale): ISearchFilter => {
  const {
    field,
    placeholder = locale.search,
    entityType,
    maxEntries = 1,
    minLength = 1,
    queryField = 'title',
    limit = 20,
    labelField = 'title'
  } = options;
  return {
    type: 'search',
    field: field,
    placeholder: placeholder,
    maxEntries: maxEntries,
    minLength: minLength,
    getOptionsByValues: (values: Array<string | number>) =>
      searchById(
        entityType,
        values.map((v) => v.toString())
      ).then(optionsFromEntities(labelField)),
    searchOptions: (query: string) =>
      searchByField(entityType, query, queryField, limit).then(optionsFromEntities(labelField))
  };
};
