/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {createIconSizesCss} from './createIconSizesCss';

const fillSuccessColorCss = css`
  & {
    fill: var(--success-color);
  }
`;

const strokeSuccessColorCss = css`
  & {
    stroke: var(--success-color);
  }
`;

const sizesCss = createIconSizesCss(20, 34);

export const Success = () => {
  return (
    <div css={sizesCss}>
      <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.99 7C11.47 7 7 11.48 7 17C7 22.52 11.47 27 16.99 27C22.52 27 27 22.52 27 17C27 11.48 22.52 7 16.99 7ZM9 17C9 21.42 12.58 25 17 25C21.42 25 25 21.42 25 17C25 12.58 21.42 9 17 9C12.58 9 9 12.58 9 17Z"
          fill="#41E38C"
          css={fillSuccessColorCss}
        />
        <path
          d="M13.3636 16.5454L16.0642 18.8182L20.1818 14.2727"
          stroke="#41E38C"
          strokeWidth="2"
          css={strokeSuccessColorCss}
        />
        <g filter="url(#filter0_f)">
          <mask id="path-3-inside-1" fill="white">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.99 7C11.47 7 7 11.48 7 17C7 22.52 11.47 27 16.99 27C22.52 27 27 22.52 27 17C27 11.48 22.52 7 16.99 7ZM9 17C9 21.42 12.58 25 17 25C21.42 25 25 21.42 25 17C25 12.58 21.42 9 17 9C12.58 9 9 12.58 9 17Z"
            />
          </mask>
          <path
            d="M9.04938 17C9.04938 12.6092 12.6045 9.04938 16.99 9.04938V4.95062C10.3355 4.95062 4.95062 10.3508 4.95062 17H9.04938ZM16.99 24.9506C12.6045 24.9506 9.04938 21.3908 9.04938 17H4.95062C4.95062 23.6492 10.3355 29.0494 16.99 29.0494V24.9506ZM24.9506 17C24.9506 21.3868 21.3895 24.9506 16.99 24.9506V29.0494C23.6505 29.0494 29.0494 23.6532 29.0494 17H24.9506ZM16.99 9.04938C21.3895 9.04938 24.9506 12.6132 24.9506 17H29.0494C29.0494 10.3468 23.6505 4.95062 16.99 4.95062V9.04938ZM17 22.9506C13.7118 22.9506 11.0494 20.2882 11.0494 17H6.95062C6.95062 22.5518 11.4482 27.0494 17 27.0494V22.9506ZM22.9506 17C22.9506 20.2882 20.2882 22.9506 17 22.9506V27.0494C22.5518 27.0494 27.0494 22.5518 27.0494 17H22.9506ZM17 11.0494C20.2882 11.0494 22.9506 13.7118 22.9506 17H27.0494C27.0494 11.4482 22.5518 6.95062 17 6.95062V11.0494ZM11.0494 17C11.0494 13.7118 13.7118 11.0494 17 11.0494V6.95062C11.4482 6.95062 6.95062 11.4482 6.95062 17H11.0494Z"
            fill="#41E38C"
            css={fillSuccessColorCss}
            mask="url(#path-3-inside-1)"
          />
        </g>
        <defs>
          <filter
            id="filter0_f"
            x="0"
            y="0"
            width="34"
            height="34"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="3.5" result="effect1_foregroundBlur" />
          </filter>
        </defs>
      </svg>
    </div>
  );
  /**
   * Without glow, but with proper size
   */
  // return (
  //   <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  //     <path
  //       fillRule="evenodd"
  //       clipRule="evenodd"
  //       d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM2 10C2 14.42 5.58 18 10 18C14.42 18 18 14.42 18 10C18 5.58 14.42 2 10 2C5.58 2 2 5.58 2 10Z"
  //       fill="#41E38C"
  //       css={fillSuccessColorCss}
  //     />
  //     <path
  //       d="M6.36377 9.54568L9.0643 11.8184L13.182 7.27295"
  //       stroke="#41E38C"
  //       strokeWidth="2"
  //       css={strokeSuccessColorCss}
  //     />
  //   </svg>
  // );
};
