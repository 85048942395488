import {IUserInfo} from '@netvision/lib-api-repo/dist/src/types';
import {useEffect, useState} from 'react';
import store from '../store';

export const useGetUserInfo = () => {
  const [userInfo, setUserInfo] = useState<IUserInfo>({} as IUserInfo);

  useEffect(() => {
    const fetchUserInfo = () => {
      'getUserInfo' in store.api &&
        store.api.getUserInfo().then((response) => {
          if ('userId' in response) {
            setUserInfo(response);
          }
        });
    };

    try {
      fetchUserInfo();
    } catch (e) {
      console.error(e);
    }
  }, [store.api]);

  return userInfo;
};
