const DEFAULT_TABLE_SETTINGS = {
  defaultGroupingFields: ['tboEventType'],
  dataSource: {
    type: 'TboEvent',
    extraAttrs: ['bbox']
  },
  defaultPage: {
    rows: 25,
    rowsPerPage: [25, 50, 100, 250],
    sortField: 'timestamp',
    sortOrder: -1
  }
};

const DEFAULT_CONFIG = {
  eventName: 'TboEvent',
  tableSettings: DEFAULT_TABLE_SETTINGS
};

export {DEFAULT_CONFIG as DEFAULT_CONFIG_TBO};
