import { IFilterDescription, INgsiDataSource, ISerializers, IColumn } from '@netvision/lib-react-table';
import { DEFAULT_CONFIG_ASSIGNMENT } from './AssignmentEvent';
import { DEFAULT_CONFIG_TBO } from './TboEvent';
import { IAssignmentEventType } from '../models/AssignmentEvent/IAssignmentEventType';
import { IAssignmentEvent } from '../models/AssignmentEvent/IAssignmentEvent';
import { ITboEvent, ITboEventType } from '../models';
import { EventsTypes } from '../models/';
import { createTableColumns, createTableFilters, createSerializeTableData } from '../helpers';
import { IEntity, ISearchQueryPayload } from '@netvision/lib-api-repo';
import { MetaDataType } from '../Root';
import { useLocale } from '../hooks/useLocale';

export type EventType = IAssignmentEventType | ITboEventType;
export type EventStructure = IAssignmentEvent & ITboEvent;
export type EventDataSource = INgsiDataSource<EventStructure, string>;

export type EventTableConfig = {
  defaultGroupingFields: string[];
  dataSource: EventDataSource;
  defaultPage: {
    rows: number;
    rowsPerPage: number[];
    sortField: string | null;
    sortOrder?: number;
    invariableFilterQuery?: ISearchQueryPayload;
  };
  serializer: ISerializers<EventStructure, string>;
  useGetFilterDescription: () => IFilterDescription<string>;
  useGetAllColumns: () => IColumn<IEntity, string>[];
};

export type EventConfigMap = {
  [k: string]: EventConfigMapItem;
};

export type EventConfigMapItem = {
  eventName: string;
  tableSettings: EventTableConfig;
};

const eventConfigMapDefaults = {
  [EventsTypes.TboEvent]: DEFAULT_CONFIG_TBO,
  [EventsTypes.AssignmentEvent]: DEFAULT_CONFIG_ASSIGNMENT
};

export const useEventConfigDataMap = (props: MetaDataType | null, columnOverride?: {}): EventConfigMapItem | null => {
  const defaultLocale = useLocale();
  if (!props) return null

  const {eventType, tableSetting, locale} = props;
  const columns = createTableColumns(tableSetting, locale, eventType, columnOverride);
  const columnsFilterData = createTableFilters(tableSetting, defaultLocale);
  const serializeData = createSerializeTableData(tableSetting, locale);

  return {
    ...eventConfigMapDefaults[eventType],
    tableSettings: {
      ...eventConfigMapDefaults[eventType].tableSettings,
      useGetAllColumns: () => columns,
      useGetFilterDescription: () => columnsFilterData,
      serializer: serializeData
    }
  };
};
