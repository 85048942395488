import React, { createContext, FC, useContext } from 'react';
import { LocaleProvider } from './hooks/useLocale';
import { Main } from './components/Main';
import { LangProvider } from './providers/LangProvider';
import { IWidgetProps } from './IWidgetProps';
import { FetchEntityProvider } from './hooks/useEntity';
import { ApiRepositoryProvider } from './hooks/useApiRepository'
import { EventConfigMapItem, useEventConfigDataMap } from './events-config';
import { LocaleMetaData, TableSettings, EventsTypes } from './models';
import { useGetMetaData } from './hooks';

const WidgetPropsContext = createContext<IWidgetProps>(null!);
const EventDataTableProvider = createContext<EventConfigMapItem['tableSettings']>(null!);

export function useWidgetProps() {
  return useContext(WidgetPropsContext);
}

export function useEventData() {
  return useContext(EventDataTableProvider);
}

export type MetaDataType = {
  eventType: EventsTypes;
  tableSetting: TableSettings<any>;
  locale: LocaleMetaData;
};

const Root: FC<IWidgetProps> = (widgetProps) => {
  return (
    <ApiRepositoryProvider lib={widgetProps.props?.lib}>
      <RootContent widgetProps={widgetProps} />
    </ApiRepositoryProvider>
  );
};

const RootContent: FC<{ widgetProps: IWidgetProps }> = ({ widgetProps }) => {
  const metaData = useGetMetaData(widgetProps.props?.eventName || EventsTypes.TboEvent, 'ru');
  const eventConfig = useEventConfigDataMap(metaData, widgetProps.props?.columns);

  if (!eventConfig) return null
  
  return (
    <WidgetPropsContext.Provider value={widgetProps}>
      <LangProvider>
        <LocaleProvider>
          <FetchEntityProvider>
            <EventDataTableProvider.Provider value={eventConfig.tableSettings}>
              <Main />
            </EventDataTableProvider.Provider>
          </FetchEntityProvider>
        </LocaleProvider>
      </LangProvider>
    </WidgetPropsContext.Provider>
  )
}

export default Root;
