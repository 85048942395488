import React from 'react';
import {get} from 'lodash-es';

import {
  TextCell,
  TextIconCell,
  DateTimeCell,
  RefCell,
  ObjectCell,
  RefArrayCell,
  RefTransformCell,
  PictureCell,
  ScheduleCell,
  EnumCell
} from '../components/cells';

import {
  CellType,
  isTextCell,
  isTextIconCell,
  isDateTimeCell,
  isRefCell,
  isObjectCell,
  isRefArrayCell,
  isRefTransform,
  isPictureCell,
  isScheduleCell,
  EventsTypes,
  isEnumCell,
  isTboContainerCell
} from '../models';

import {Containers as TboContainers} from '../components/helpers/TboContainers';

export const getCellByType = <T extends {[k: string]: any}, C extends CellType>(
  rowData: T,
  cell: C,
  field: string,
  entityType?: EventsTypes,
  attrs?: Record<string, string>
) => {
  const value = get(rowData, field);
  // Base Cells

  if (isTextCell(cell)) return <TextCell text={value} />;
  if (isTextIconCell(cell)) return <TextIconCell data={rowData as any} cell={cell} />;
  if (isDateTimeCell(cell)) return <DateTimeCell timeStamp={value} />;
  if (isRefCell(cell)) return <RefCell rowData={rowData} description={cell} />;
  if (isRefArrayCell(cell)) return <RefArrayCell rowData={rowData} cell={cell} />;
  if (isRefTransform(cell)) return <RefTransformCell description={cell} rowData={rowData} />;
  if (isObjectCell(cell)) return <ObjectCell object={value} cell={cell} />;
  if (isEnumCell(cell)) return <EnumCell cell={cell} value={value} />;
  if (isScheduleCell(cell))
    return (
      entityType && <ScheduleCell rowData={rowData} eventType={entityType} field={field} cell={cell} attrs={attrs} />
    );

  // Uniq Cells
  if (isPictureCell(cell)) return <PictureCell event={rowData} />;
  if (isTboContainerCell(cell)) return <TboContainers rowData={rowData as any} />; //Todo
  return null;
};
