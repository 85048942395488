import {IEntity} from '@netvision/lib-api-repo';
import {createBatchQueue, IBatchQueue} from '../utils/batchQueue';
import store from '../store';

type _IQueue = IBatchQueue<[string, string], IEntity>;

const cache = new Map();
const batchMap = new Map();

type fetchEntityArgs = {
  <T extends IEntity>(type: string, id: string, cacheArg?: Map<any, any>, batchMapArg?: Map<any, any>): Promise<T>;
};

export const fetchEntity: fetchEntityArgs = (type, id, cacheArg, batchMapArg) => {
  !cacheArg && (cacheArg = cache);
  !batchMapArg && (batchMapArg = batchMap);

  return new Promise((resolve, reject) => {
    const cacheKey = JSON.stringify([type, id]);
    const cached = cacheArg!.get(cacheKey);
    if (cached) return resolve(cached as any);

    const queueKey = JSON.stringify([type, id]);
    let queue: _IQueue | undefined = batchMapArg!.get(queueKey);
    if (typeof queue === 'undefined') {
      queue = createEntityQueue();
      batchMapArg!.set(queueKey, queue);
    }
    queue.add([type, id], reject, (output) => {
      cacheArg!.set(cacheKey, output);
      resolve(output as any);
    });
  });
};

const createEntityQueue = () =>
  createBatchQueue<[string, string], IEntity>(34, (input) => {
    if (input.length <= 0) return Promise.resolve([]);

    const [type, id] = input[0].args;
    return store.api
      .getEntitiesList({
        limiter: {type, id}
      })
      .then(({results}) => results)
      .then(([data]) => {
        if (!data) return [];
        return input.map(({$id}) => ({$id, result: data as IEntity}));
      })
      .catch((e) => {
        console.error(e);
        return [];
      });
  });
