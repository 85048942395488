import { ISearchQueryPayload } from "@netvision/lib-api-repo";

const DEFAULT_TABLE_SETTINGS = {
  defaultGroupingFields: ['eventType', 'assignment.title'],
  dataSource: {
    type: 'AssignmentEvent'
  },
  defaultPage: {
    rows: 50,
    rowsPerPage: [50, 125, 250],
    sortField: 'timestamp',
    sortOrder: -1,
    invariableFilterQuery: {
      value: 'Camera',
      operator: '==',
      key: 'entityType'
    } as ISearchQueryPayload
  }
};

const DEFAULT_CONFIG = {
  eventName: 'AssignmentEvent',
  tableSettings: DEFAULT_TABLE_SETTINGS
};

export { DEFAULT_CONFIG as DEFAULT_CONFIG_ASSIGNMENT };
