import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';
// @ts-ignore
import singleSpaReact from 'single-spa-react';
import { configure } from 'mobx';

configure({ isolateGlobalState: true })

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorBoundary(err: any, info: any, props: any) {
    // https://reactjs.org/docs/error-boundaries.html
    console.error(err, info, props);
    return null;
  }
});

export const bootstrap = lifecycles.bootstrap;
export const mount = lifecycles.mount;
export const unmount = lifecycles.unmount;
export const update = lifecycles.update;
