import { upperFirst } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { EventsTypes, EventMetaData } from '../../models';
import { MetaDataType } from '../../Root';
import store from '../../store';

const getLocaleField = (lang: string) => `locale${upperFirst(lang.toLowerCase())}`;

export const useGetMetaData = (type: string, lang: string): MetaDataType | null => {
  const [metaData, setMetaData] = useState<EventMetaData>(null!);
  const localeField = getLocaleField(lang);

  useEffect(() => {
    let aborted = false;
    loadMetaData(type, [localeField, 'viewEventJournal'].join(',')).then((res) => {
      //Todo Validate result
      if (aborted) return;
      if (!res) return;
      setMetaData({
        $entityType: type,
        $locale: res?.[localeField]?.value,
        viewTable: res?.['viewEventJournal']?.value
      });
    });

    return () => {
      aborted = true;
    };
  }, [localeField, type, store.api]);

  return useMemo(() => normalizedMetaData(metaData), [metaData]);
};

const normalizedMetaData = (metaData: EventMetaData): ReturnType<typeof useGetMetaData> => {
  if (!metaData) return null;
  return {
    eventType: metaData.$entityType as EventsTypes,
    locale: {
      attrs: metaData.$locale.attrs
    },
    tableSetting: metaData.viewTable
  };
};

type MetaStructure = {
  [k: string]: { value: any };
};

const loadMetaData = (type: string, attrs: string) => {
  return store.api.getEntitiesList({
    limiter: {
      id: `EntityTypeMetadata:${type}`,
      type: 'EntityTypeMetadata',
      keyValues: false
    },
    filter: { attrs }
  })
    .then(({results}) => (results[0] || null) as MetaStructure)
    .catch((err) => {
      console.error(err);
      return null;
    });
}
