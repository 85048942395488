import React, { createContext, ReactNode, useContext, useEffect } from "react";
import { TLibProp } from '@netvision/lib-api-repo';
import apiInstance from '../store';

export type TApiRepositoryProviderProps = {
  lib?: TLibProp
  value?: typeof apiInstance
  children: ReactNode
};
const ApiRepositoryContext = createContext(apiInstance);
export const useApiRepository = () => useContext(ApiRepositoryContext);

export const ApiRepositoryProvider = ({ lib, value = apiInstance, children }: TApiRepositoryProviderProps) => {
  const { setApiRepository } = useApiRepository()
  useEffect(() => {
    setApiRepository(lib)
  }, [lib])

  if (!value.isInit) return null

  return (
    <ApiRepositoryContext.Provider value={value}>
      {children}
    </ApiRepositoryContext.Provider>
  )
}
