/** @jsx jsx */
import {css, jsx, keyframes} from '@emotion/react';
import {
  createContext,
  FC,
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {useIntersectionObserver} from '../../../providers/IntersectionObserverProvider';
import {debounce} from '../../../utils/debounce';
import {createPortal} from 'react-dom';
import {attachZoomHandler} from '@netvision/front-utils/lib/common/handleZoom';
import {getCanvasInitState} from '../../../canvas/canvasWidget';
import {useWidgetProps} from '../../../Root';
import {IWidgetProps} from '../../../IWidgetProps';
import {Widget} from '@netvision/lib-widget-renderer';
import {createDisposers} from '../../../utils/disposers';
import {ErrorPicture} from './ErrorPicture';
import {
  WIDTH,
  HEIGHT,
  TOP,
  RIGHT,
  $picture,
  $zoomIndicator,
  $bigPicture,
  $bigVideo,
  $closeButton,
  $overlay
} from './style';
import {EventsTypes} from '../../../models';
import {useApiRepository} from '../../../hooks/useApiRepository';
import {TUnionRepo} from '@netvision/lib-api-repo';

export interface IPictureProps {
  event: any;
}

type ILoadingStatus = 'init' | 'loading' | 'loaded' | 'error';
type IPreview = {type: 'image' | 'video'; url: string};
type ISize = {width: number; height: number};

const fetchPreviewFromStream = async (
  api: TUnionRepo,
  camera: {
    id: string;
    type: string;
  },
  signal: AbortSignal,
  timestamp: number
) => {
  const {results} = await api.getEntitiesList<{
    snapshotStreamUrl?: string;
  }>({
    limiter: {
      type: 'Stream'
    },
    filter: {
      attrs: 'snapshotStreamUrl',
      q: [
        {
          key: 'cameraId',
          operator: '==',
          value: camera.id
        }
      ]
    }
  });

  const [previewData] = results;
  if (!previewData) return;

  const {snapshotStreamUrl} = previewData;
  if (!snapshotStreamUrl) return Promise.resolve(null);
  const resp = await fetch(
    decodeURIComponent(`${snapshotStreamUrl}&start=${Math.round(timestamp / 1000)}`),
    // @ts-ignore
    {signal, headers: api.getHeaders()}
  );

  const hasPreview = ['image/jpeg', 'video/mp4', 'image/png'].includes(resp.headers.get('Content-Type') || '');
  if (!hasPreview) return null;
  return await resp.blob();
};

export const PictureCell: FC<IPictureProps> = ({event}) => {
  const {cameraId, timestamp, id, assignment} = event;
  const {api} = useApiRepository();

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const isIntersectingRef = useRef(false);

  const [status, setStatus] = useState<ILoadingStatus>('init');
  const [preview, setPreview] = useState<null | IPreview>(null);
  const [naturalSize, setNaturalSize] = useState<null | ISize>(null);

  const [bigPictureEvent, setBigPicture] = useBigPicture();
  const {props: {eventName = EventsTypes.AssignmentEvent} = {}} = useWidgetProps();
  const subscribe = useIntersectionObserver();

  useEffect(() => {
    setStatus(isIntersectingRef.current ? 'loading' : 'init');
    setPreview(null);
  }, [cameraId, timestamp]);

  useEffect(() => {
    if (!videoRef.current) return;

    const debounced = debounce((entry) => {
      if (entry.isIntersecting) {
        setStatus((prev) => (prev === 'init' ? 'loading' : prev));
      }
    }, 100);

    return subscribe(videoRef.current, (entry) => {
      isIntersectingRef.current = entry.isIntersecting;
      debounced(entry);
    });
  }, [subscribe]);

  useEffect(() => {
    const video = videoRef.current;
    if (!video || !preview) return;
    const disposers = createDisposers();
    let canceled = false;

    disposers.add(() => (canceled = true));

    if (preview.type === 'image') {
      const img = new Image();
      img.src = preview.url;
      img.onload = () => {
        if (!canceled) {
          setNaturalSize({
            height: img.naturalHeight,
            width: img.naturalWidth
          });
        }
      };
    } else if (preview.type === 'video') {
      const onLoad = () => {
        setNaturalSize({
          height: video.videoHeight,
          width: video.videoWidth
        });
      };
      video.addEventListener('loadstart', onLoad, {once: true});
      disposers.add(() => {
        video.removeEventListener('loadstart', onLoad);
      });
    }

    return disposers.flush;
  }, [preview]);

  useEffect(() => {
    if (status !== 'loading') return;
    if (!videoRef.current) return;
    const abr = new AbortController();
    const {signal} = abr;
    const {entityId, entityType} = assignment;
    let previewUrl: string = '';

    const fetchPreview = async () => {
      try {
        if (signal.aborted) return;
        let previewBlob: undefined | null | Blob;
        const hasMethod = 'getPreview' in api;
        if (hasMethod) previewBlob = await api.getPreview({id, timestamp, signal, eventName});

        if (!previewBlob) {
          previewBlob = await fetchPreviewFromStream(api, {id: entityId, type: entityType}, signal, timestamp);
        }

        if (previewBlob) {
          previewUrl = URL.createObjectURL(previewBlob);
          setPreview({
            type: previewBlob.type.startsWith('video') ? 'video' : 'image',
            url: previewUrl
          });
          setStatus('loaded');
          return;
        }
        setStatus('error');
      } catch (e) {
        console.error(e);
        setStatus('error');
      }
    };

    fetchPreview();

    return () => {
      abr.abort();
    };
  }, [status, cameraId, timestamp, id, eventName, assignment, api]);

  useEffect(() => {
    if (!preview) return;
    return () => window.URL.revokeObjectURL(preview.url);
  }, [preview]);

  const src = preview?.type === 'video' ? preview.url : undefined;
  const poster = preview?.type === 'image' ? preview.url : undefined;

  const onClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      markEvent(e.nativeEvent);
      e.stopPropagation();
      const {left} = e.currentTarget.getBoundingClientRect();
      if (bigPictureEvent === event) {
        setBigPicture(null);
      } else {
        setBigPicture({src, poster, event, left, naturalSize});
      }
    },
    [setBigPicture, src, poster, event, bigPictureEvent, naturalSize]
  );

  useEffect(() => {
    if (bigPictureEvent === event) {
      setBigPicture({event, poster, src, left: videoRef.current?.getBoundingClientRect().left, naturalSize});
    }
  }, [setBigPicture, src, poster, event, bigPictureEvent, naturalSize]);

  return (
    <div css={$picture} onClick={status === 'error' ? () => {} : onClick}>
      {status === 'error' && <ErrorPicture />}
      <video ref={videoRef} src={src} poster={poster} />
      {status === 'loaded' && (
        <div data-name={'magnify'} data-show={bigPictureEvent === event} css={$zoomIndicator}>
          <i className={'mdi mdi-24px mdi-magnify-plus-outline'} />
        </div>
      )}
      {status === 'loading' && (
        <i data-name={'icon'} data-status={status} className={'mdi mdi-24px mdi-loading mdi-spin'} />
      )}
    </div>
  );
};

type IBigPictureParams = {src?: string; poster?: string; event: any; left?: number; naturalSize: ISize | null};
const bigPictureContext = createContext<readonly [any | null, (params: IBigPictureParams | null) => void]>(null!);

const useBigPicture = () => useContext(bigPictureContext);

export const BigPictureProvider: FC = ({children}) => {
  const {mountChildren, areas, props: {canvasStyles} = {}} = useWidgetProps();

  const [params, setParams] = useState<IBigPictureParams | null>(null);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const overlayRef = useRef<HTMLDivElement | null>(null);

  const [node] = useState(() => {
    const node = document.createElement('div');
    node.style.position = 'absolute';
    node.style.right = RIGHT;
    node.style.top = TOP;
    node.style.width = WIDTH;
    node.style.height = HEIGHT;
    node.style.display = 'flex';
    node.style.flexDirection = 'column';
    node.style.justifyContent = 'center';
    node.style.alignItems = 'flex-end';
    node.style.pointerEvents = 'none';
    return node;
  });

  // set popup position on params change
  useEffect(() => {
    if (params?.left) {
      node.style.right = `calc(var(--spacer-sm) + ${window.innerWidth - params.left + 'px'})`;
    }
  }, [node, params]);

  // append element to DOM and add on outside click listener
  const noParams = params === null;
  useEffect(() => {
    if (!noParams) {
      document.body.appendChild(node);
      const onClick = (e: MouseEvent) => {
        if (!isEventMarked(e)) {
          setParams(null);
        }
      };
      window.addEventListener('click', onClick);
      return () => {
        window.removeEventListener('click', onClick);
        document.body.removeChild(node);
      };
    }
    return undefined;
  }, [node, noParams]);

  // attach/detach zoom handler
  useEffect(() => {
    if (!noParams) {
      const video = videoRef.current;
      const videoParent = video?.parentElement;
      const overlayContainer = overlayRef.current;
      if (!video || !videoParent || !overlayContainer) {
        return undefined;
      }
      return attachZoomHandler(videoParent, [video, overlayContainer]);
    }
    return undefined;
  }, [noParams]);

  const event = params?.event ?? null;
  const naturalSize = params?.naturalSize ?? null;
  // mountCanvas
  useEffect(() => {
    if (event && naturalSize) {
      const video = videoRef.current;
      const overlayContainer = overlayRef.current;
      if (!video || !overlayContainer) {
        return undefined;
      }
      const canvas = getCanvasWidget(areas);
      if (!canvas) {
        return undefined;
      }
      const overlayParams = {
        overlayContainer,
        overlayBase: video,
        naturalHeight: naturalSize.height,
        naturalWidth: naturalSize.width
      };
      const initState = getCanvasInitState(event, naturalSize, canvasStyles || {});
      return mountChildren(overlayContainer, [
        {
          ...canvas,
          props: {
            ...canvas.props,
            overlay: overlayParams,
            initState
          }
        }
      ]);
    }
    return undefined;
  }, [event, naturalSize, mountChildren, areas, canvasStyles]);

  const contextValue = useMemo(() => [event, setParams] as const, [event]);

  // mark event on inside click
  const onClick = useCallback<MouseEventHandler<HTMLDivElement>>((e) => {
    markEvent(e.nativeEvent);
  }, []);

  const onClose = useCallback(() => {
    setParams(null);
  }, []);

  return (
    <bigPictureContext.Provider value={contextValue}>
      {children}
      {params &&
        createPortal(
          <div css={$bigPicture} onClick={onClick}>
            <video ref={videoRef} src={params.src} poster={params.poster} css={$bigVideo} />
            <div ref={overlayRef} css={$overlay} />
            <div role={'button'} onClick={onClose} css={$closeButton}>
              <i className={'mdi mdi-18px mdi-close'} />
            </div>
          </div>,
          node
        )}
    </bigPictureContext.Provider>
  );
};

const markEvent = (e: Object) => {
  Object.assign(e, {
    [markSymbol]: null
  });
};
const isEventMarked = (e: Object) => {
  return e.hasOwnProperty(markSymbol);
};
const markSymbol = Symbol('Picture.tsx');

function getCanvasWidget(areas: IWidgetProps['areas']): Widget | null {
  let canvas = null;
  if (Array.isArray(areas)) {
    areas.forEach((area) => {
      if (area.name === 'canvas' && area.children && area.children.length > 0) {
        canvas = area.children[0];
      }
    });
  }
  return canvas;
}
